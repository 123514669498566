define("discourse/plugins/retort/discourse/lib/retort", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner"], function (_exports, _ajax, _ajaxError, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.create({
    topic: {
      postStream: {
        posts: []
      }
    },
    initialize(messageBus, topic) {
      if (this.topic.id) {
        messageBus.unsubscribe(`/retort/topics/${this.topic.id}`);
      }
      this.set('topic', topic);
      messageBus.subscribe(`/retort/topics/${this.topic.id}`, _ref => {
        let {
          id,
          retorts
        } = _ref;
        const post = this.postFor(id);
        if (!post) {
          return;
        }
        post.setProperties({
          retorts
        });
        this.get(`widgets.${id}`).scheduleRerender();
      });
      const siteSettings = (0, _getOwner.getOwner)(this).lookup("site-settings:main");
      this.set('siteSettings', siteSettings);
    },
    postFor(id) {
      return (this.get('topic.postStream.posts') || []).find(p => p.id == id);
    },
    storeWidget(helper) {
      if (!this.get('widgets')) {
        this.set('widgets', {});
      }
      this.set(`widgets.${helper.getModel().id}`, helper.widget);
    },
    updateRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: 'POST',
        data: {
          retort
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split('|');
      return categories.map(cat => cat.toLowerCase()).filter(Boolean);
    },
    disabledFor(postId) {
      const post = this.postFor(postId);
      if (!post) {
        return true;
      }
      if (!post.topic.details.can_create_post) {
        return true;
      }
      if (post.get('topic.archived')) {
        return true;
      }
      const categoryName = post.get('topic.category.name');
      const disabledCategories = this.disabledCategories();
      return categoryName && disabledCategories.includes(categoryName.toString().toLowerCase());
    },
    openPicker(post) {
      this.set('picker.isActive', true);
      this.set('picker.post', post);
    },
    setPicker(picker) {
      this.set('picker', picker);
      this.set('picker.emojiSelected', retort => this.updateRetort(picker.post, retort).then(() => picker.set('isActive', false)));
    }
  });
});